<template>
	<div class="page">
		<div>
			<el-button @click="dialog.addRole = true;role={}" type="primary" plain size="mini">+ 新增
			</el-button>
		</div>
		<div ref="tableBox" style="margin-top: 0px;flex-grow: 1; background-color: white;padding-bottom: 20px;">
			<el-table size="small" header-cell-class-name="tableHeader" :data="tableData" border
				:max-height="tableHeight" style="width: 100%;">
				<el-table-column fixed prop="title" label="角色名称" width="240px">
				</el-table-column>
				<el-table-column label="角色权限">
					<template #default="scope">
						<span style="margin-right: 10px;" :key="index"
							v-for="(item,index) in scope.row.menus">{{item.title}}</span>
					</template>
				</el-table-column>
				<el-table-column width="200px" label="状态">
					<template #default="scope">
						<el-switch v-model="scope.row.state" :inline-prompt="false" :active-value="1"
							:inactive-value="0" active-color="#13ce66" inactive-color="#ff4949" active-text="启用"
							inactive-text="禁用" />
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="100">
					<template #default="scope">
						<el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
						<el-button @click="handleClick(scope.row)" type="text" size="small">删除</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div style="margin-top: 20px; text-align: right;">
				<el-pagination background layout="prev, pager, next" :total="1000"></el-pagination>
			</div>
		</div>

		<el-dialog v-model="dialog.addRole" title="添加角色" width="50%" top="5vh">
			<el-form :inline="true" v-model="role" label-width="120px">
				<el-form-item label="角色名称">
					<el-input v-model="role.title"></el-input>
				</el-form-item>
				<br />
				<el-form-item label="角色权限">
					<div
						style="border: 1px solid #DCDFE6; padding: 10px; box-sizing: border-box; width: 300px; max-height: 300px; overflow: auto;">
						<el-tree ref="tree" v-model="roleSelect" :data="roleList" show-checkbox node-key="id" />
					</div>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="add" plain type="primary" size="small">
						添加
					</el-button>
					<el-button plain size="small">
						取消
					</el-button>
				</span>
			</template>
		</el-dialog>
		
		<el-dialog v-model="dialog.editRole" title="编辑角色" width="50%" top="5vh">
			<el-form :inline="true" v-model="role" label-width="120px">
				<el-form-item label="角色名称">
					<el-input v-model="role.title"></el-input>
				</el-form-item>
				<br />
				<el-form-item label="角色权限">
					<div
						style="border: 1px solid #DCDFE6; padding: 10px; box-sizing: border-box; width: 300px; max-height: 300px; overflow: auto;">
						<el-tree ref="editTree" v-model="roleSelect" :data="roleList" show-checkbox node-key="id" />
					</div>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="postEdit" plain type="primary" size="small">
						修改
					</el-button>
					<el-button plain size="small">
						取消
					</el-button>
				</span>
			</template>
		</el-dialog>
	</div>

</template>

<script>
	import {
		getMenuList,
		addRole,
		getRoles,
		editRole
	} from "../../api/SystemApi.js"
	export default {
		data() {
			return {
				dialog: {
					addRole: false,
					editRole:false
				},
				role: {},
				roleList: [],
				roleSelect: [],
				tableData: [],
			}
		},

		mounted() {
			this.tableInit()
			this.getMenus()
			var that = this
			getRoles().then(res => {
				that.tableData = res.data.data
			})
		},

		methods: {
			tableInit: function() {
				var that = this
				let tableBox = this.$refs.tableBox
				this.tableHeight = tableBox.offsetHeight - 60
				setTimeout(function() {
					tableBox.children[0].style.maxHeight = that.tableHeight + "px"
				}, 0)
			},

			getMenus() {
				var that = this
				getMenuList().then(res => {
					let data = res.data.data
					let arr = []
					for (let i = 0; i < data.length; i++) {
						arr.push({
							id: "menu:" + data[i].id,
							label: data[i].title
						})
					}
					that.roleList = arr
				})
			},
			
			edit:function(data){
				this.role = data
				var arr = []
				for(let i = 0 ; i < data.menus.length ; i ++){
					arr.push("menu:"+data.menus[i].id)
				}
				this.dialog.editRole = true
				var that = this
				setTimeout(function(){
					that.$refs.editTree.setCheckedKeys(arr)
				},50)
				
			},

			add() {
				var selectArr = this.$refs.tree.getCheckedKeys()
				var targetArr = []
				for (let i = 0; i < selectArr.length; i++) {
					var item = selectArr[i].split(":")
					if (item[0] == "menu") {
						targetArr.push({
							id: item[1]
						})
					}
				}
				var that = this
				this.role.menus = targetArr
				addRole(this.role).then(() => {
					getRoles().then(res => {
						that.tableData = res.data.data
					})
				})
			},
			
			postEdit:function(){
				var selectArr = this.$refs.editTree.getCheckedKeys()
				var targetArr = []
				var that = this
				for (let i = 0; i < selectArr.length; i++) {
					var item = selectArr[i].split(":")
					if (item[0] == "menu") {
						targetArr.push({
							id: item[1]
						})
					}
				}
				this.role.menus = targetArr
				editRole(that.role).then(() => {
					getRoles().then(res => {
						that.tableData = res.data.data
						
					})
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.page {
		background-color: white;
		padding: 10px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		height: 100%;
	}
</style>
